<template>
  <div
    class="ui-date-picker"
    :class="{
      'ui-date-picker--disabled': disabled,
      'ui-date-picker--default': design === 'default',
    }"
  >
    <label
      :for="id"
      class="ui-date-picker__label"
      :class="{
        'ui-date-picker__label--error': error,
        'ui-date-picker__label--full': design === 'full',
      }"
      v-if="design === 'full'"
    >
      {{ label }}
    </label>
    <div class="ui-date-picker__wrapper">
      <date-picker
        :locale="locale"
        :value="dateValue"
        :popover="{ placement: 'top', visibility: 'click', positionFixed: true }"
        :attributes="attributes"
        :is-range="mode === 'range' ? true : false"
        mode="date"
        :is-dark="isDark"
        :min-date="minDate ? minDate : null"
        :max-date="maxDate ? maxDate : null"
        @popoverWillShow="onFocus"
        @popoverWillHide="onBlur"
        @input="onInput"
      >
        <template v-slot="{ inputValue, inputEvents, togglePopover }">
          <div v-if="mode === 'range'" class="ui-date-picker__wrapper--container">
            <input
              :id="`${id}-start`"
              class="ui-date-picker__wrapper__field"
              :class="{
                'ui-date-picker__wrapper__field--focused': isFocused,
                'ui-date-picker__wrapper__field--error': error,
              }"
              :placeholder="design === 'minimalist' || design === 'full' ? label[0] : ' '"
              :value="inputValue.start"
              v-on="inputEvents.start"
              :autocomplete="autocomplete ? 'on' : 'off'"
              :disabled="disabled"
              readonly="readonly"
            />
            <span class="ui-date-picker__wrapper__icon-from-to backoffice-icons"> next </span>

            <input
              :id="`${id}-end`"
              class="ui-date-picker__wrapper__field"
              :class="{
                'ui-date-picker__wrapper__field--focused': isFocused,
                'ui-date-picker__wrapper__field--error': error,
              }"
              :placeholder="design === 'minimalist' || design === 'full' ? label[1] : ' '"
              :value="inputValue.end"
              v-on="inputEvents.end"
              :autocomplete="autocomplete ? 'on' : 'off'"
              :disabled="disabled"
              readonly="readonly"
            />
          </div>
          <div v-else>
            <input
              :id="id"
              class="ui-date-picker__wrapper__field"
              :class="{
                'ui-date-picker__wrapper__field--focused': isFocused,
                'ui-date-picker__wrapper__field--icon-left': icon && !reverse,
                'ui-date-picker__wrapper__field--icon-right': icon && reverse,
                'ui-date-picker__wrapper__field--error': error,
              }"
              :placeholder="design === 'minimalist' || design === 'full' ? label : ' '"
              :value="inputValue"
              v-on="inputEvents"
              :autocomplete="autocomplete ? 'on' : 'off'"
              :disabled="disabled"
              readonly="readonly"
            />
            <span
              class="ui-date-picker__wrapper__icon backoffice-icons"
              :class="{
                'ui-date-picker__wrapper__icon--left': icon && !reverse,
                'ui-date-picker__wrapper__icon--right': icon && reverse,
                'ui-date-picker__wrapper__icon--error': error,
              }"
              v-if="icon"
              @click="togglePopover"
            >
              {{ icon }}
            </span>
          </div>
        </template>
      </date-picker>
      <label
        :for="id"
        class="ui-date-picker__wrapper__label"
        :class="{
          'ui-date-picker__wrapper__label--highlight': hasDate,
          'ui-date-picker__wrapper__label--focused': isFocused,
          'ui-date-picker__wrapper__label--icon-left': icon && !reverse,
          'ui-date-picker__wrapper__label--icon-right': icon && reverse,
          'ui-date-picker__wrapper__label--error': error,
          'ui-date-picker__wrapper__label--hidden': design === 'minimalist',
        }"
      >
        {{ label }}
      </label>
    </div>
    <div class="ui-date-picker__helper" :class="{ 'ui-date-picker__helper--error': error }" v-if="helper || error">
      <slot>{{ helper }}</slot>
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'UiDatePicker',
  components: {
    DatePicker,
  },
  model: {
    prop: 'dateValue',
  },
  props: {
    dateValue: {
      type: [String, Date, Object],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Array],
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    helper: {
      type: String,
      required: false,
      default: '',
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    attributes: {
      type: Array,
      required: false,
      default: () => [
        {
          key: 'today',
          dot: true,
          dates: new Date(),
        },
      ],
    },
    locale: {
      type: String,
      required: false,
      default: 'en',
    },
    mode: {
      type: String,
      required: false,
      default: '',
    },
    autocomplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    design: {
      type: String,
      required: false,
      default: 'default',
    },
    minDate: {
      type: [Date, Object, String],
      required: false,
      default: null,
    },
    maxDate: {
      type: [Date, Object, String],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isFocused: false,
    }
  },
  computed: {
    isDark() {
      return document.documentElement.getAttribute('data-theme') === 'dark'
    },
    hasDate() {
      return !!this.dateValue
    },
  },
  methods: {
    onInput(value) {
      const date = value ? value : ''
      /**
       * Emitted when the date of the input changed.
       * @event input
       * @type {Event}
       */
      this.$emit('input', date)
    },
    onFocus() {
      this.isFocused = true
    },
    onBlur() {
      this.isFocused = false
    },
  },
}
</script>

<style lang="scss">
.ui-date-picker {
  @include input;

  &__label {
    @include input-label;
  }

  &__wrapper {
    @include input-wrapper;

    &--container {
      display: flex;
      gap: 4px;
      align-items: center;
    }

    &__field {
      @include input-field;
    }

    &__label {
      @include input-label;
    }

    &__icon {
      @include input-icon;
    }
  }

  &__helper {
    @include input-helper;
  }
}
</style>
