import Vue from 'vue'

export const AdjustHeight = {
  bind(el) {
    Vue.nextTick(() => {
      el.style.height = 'auto'
      el.style.height = `${el.scrollHeight + 2}px`
    })
    el.addEventListener('input', e => {
      e.target.style.height = 'auto' // eslint-disable-line no-param-reassign
      e.target.style.height = `${e.target.scrollHeight + 2}px` // eslint-disable-line no-param-reassign
    })
  },
}
