var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-date-picker",class:{
    'ui-date-picker--disabled': _vm.disabled,
    'ui-date-picker--default': _vm.design === 'default',
  }},[(_vm.design === 'full')?_c('label',{staticClass:"ui-date-picker__label",class:{
      'ui-date-picker__label--error': _vm.error,
      'ui-date-picker__label--full': _vm.design === 'full',
    },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"ui-date-picker__wrapper"},[_c('date-picker',{attrs:{"locale":_vm.locale,"value":_vm.dateValue,"popover":{ placement: 'top', visibility: 'click', positionFixed: true },"attributes":_vm.attributes,"is-range":_vm.mode === 'range' ? true : false,"mode":"date","is-dark":_vm.isDark,"min-date":_vm.minDate ? _vm.minDate : null,"max-date":_vm.maxDate ? _vm.maxDate : null},on:{"popoverWillShow":_vm.onFocus,"popoverWillHide":_vm.onBlur,"input":_vm.onInput},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var inputValue = ref.inputValue;
    var inputEvents = ref.inputEvents;
    var togglePopover = ref.togglePopover;
return [(_vm.mode === 'range')?_c('div',{staticClass:"ui-date-picker__wrapper--container"},[_c('input',_vm._g({staticClass:"ui-date-picker__wrapper__field",class:{
              'ui-date-picker__wrapper__field--focused': _vm.isFocused,
              'ui-date-picker__wrapper__field--error': _vm.error,
            },attrs:{"id":(_vm.id + "-start"),"placeholder":_vm.design === 'minimalist' || _vm.design === 'full' ? _vm.label[0] : ' ',"autocomplete":_vm.autocomplete ? 'on' : 'off',"disabled":_vm.disabled,"readonly":"readonly"},domProps:{"value":inputValue.start}},inputEvents.start)),_c('span',{staticClass:"ui-date-picker__wrapper__icon-from-to backoffice-icons"},[_vm._v(" next ")]),_c('input',_vm._g({staticClass:"ui-date-picker__wrapper__field",class:{
              'ui-date-picker__wrapper__field--focused': _vm.isFocused,
              'ui-date-picker__wrapper__field--error': _vm.error,
            },attrs:{"id":(_vm.id + "-end"),"placeholder":_vm.design === 'minimalist' || _vm.design === 'full' ? _vm.label[1] : ' ',"autocomplete":_vm.autocomplete ? 'on' : 'off',"disabled":_vm.disabled,"readonly":"readonly"},domProps:{"value":inputValue.end}},inputEvents.end))]):_c('div',[_c('input',_vm._g({staticClass:"ui-date-picker__wrapper__field",class:{
              'ui-date-picker__wrapper__field--focused': _vm.isFocused,
              'ui-date-picker__wrapper__field--icon-left': _vm.icon && !_vm.reverse,
              'ui-date-picker__wrapper__field--icon-right': _vm.icon && _vm.reverse,
              'ui-date-picker__wrapper__field--error': _vm.error,
            },attrs:{"id":_vm.id,"placeholder":_vm.design === 'minimalist' || _vm.design === 'full' ? _vm.label : ' ',"autocomplete":_vm.autocomplete ? 'on' : 'off',"disabled":_vm.disabled,"readonly":"readonly"},domProps:{"value":inputValue}},inputEvents)),(_vm.icon)?_c('span',{staticClass:"ui-date-picker__wrapper__icon backoffice-icons",class:{
              'ui-date-picker__wrapper__icon--left': _vm.icon && !_vm.reverse,
              'ui-date-picker__wrapper__icon--right': _vm.icon && _vm.reverse,
              'ui-date-picker__wrapper__icon--error': _vm.error,
            },on:{"click":togglePopover}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()])]}}])}),_c('label',{staticClass:"ui-date-picker__wrapper__label",class:{
        'ui-date-picker__wrapper__label--highlight': _vm.hasDate,
        'ui-date-picker__wrapper__label--focused': _vm.isFocused,
        'ui-date-picker__wrapper__label--icon-left': _vm.icon && !_vm.reverse,
        'ui-date-picker__wrapper__label--icon-right': _vm.icon && _vm.reverse,
        'ui-date-picker__wrapper__label--error': _vm.error,
        'ui-date-picker__wrapper__label--hidden': _vm.design === 'minimalist',
      },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1),(_vm.helper || _vm.error)?_c('div',{staticClass:"ui-date-picker__helper",class:{ 'ui-date-picker__helper--error': _vm.error }},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.helper))]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }