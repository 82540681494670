var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-textarea",class:{
    'ui-textarea--disabled': _vm.disabled,
    'ui-textarea--default': _vm.design === 'default',
  }},[(_vm.design === 'full')?_c('label',{staticClass:"ui-textarea__label",class:{
      'ui-textarea__label--error': _vm.error,
      'ui-textarea__label--full': _vm.design === 'full',
    },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"ui-textarea__wrapper"},[_c('textarea',{directives:[{name:"adjust-height",rawName:"v-adjust-height"}],staticClass:"ui-textarea__wrapper__field",class:{
        'ui-textarea__wrapper__field--icon-left': _vm.icon && !_vm.reverse,
        'ui-textarea__wrapper__field--icon-right': _vm.icon && _vm.reverse,
        'ui-textarea__wrapper__field--error': _vm.error,
      },attrs:{"id":_vm.id,"placeholder":_vm.design === 'minimalist' || _vm.design === 'full' ? _vm.label : ' ',"rows":_vm.rows,"autocomplete":_vm.autocomplete ? 'on' : 'off',"disabled":_vm.disabled},domProps:{"value":_vm.textareaValue},on:{"input":function($event){return _vm.onInput($event.target.value)}}}),(_vm.icon)?_c('span',{staticClass:"ui-textarea__wrapper__icon backoffice-icons",class:{
        'ui-textarea__wrapper__icon--left': _vm.icon && !_vm.reverse,
        'ui-textarea__wrapper__icon--right': _vm.icon && _vm.reverse,
        'ui-textarea__wrapper__icon--error': _vm.error,
      }},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),(_vm.design !== 'full')?_c('label',{staticClass:"ui-textarea__wrapper__label",class:{
        'ui-textarea__wrapper__label--icon-left': _vm.icon && !_vm.reverse,
        'ui-textarea__wrapper__label--icon-right': _vm.icon && _vm.reverse,
        'ui-textarea__wrapper__label--error': _vm.error,
        'ui-textarea__wrapper__label--hidden': _vm.design === 'minimalist',
      },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]),(_vm.helper || _vm.error)?_c('div',{staticClass:"ui-textarea__helper",class:{ 'ui-textarea__helper--error': _vm.error }},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.helper))]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }